import React, { ReactElement, SyntheticEvent } from 'react';
import { useTheme } from '@components/theme';
import classNames from 'classnames';
import Caret from '@components/shared/images/atoms/Caret/Caret';
import If from '@components/shared/conditional/If/If';
import formatCost from '@lib/formatCost';
import useToggle from '@hooks/useToggle';
import HolidaySale from '@hooks/useHolidaySale/HolidaySale';
import {
  PACKAGES,
  PRICES,
  salePackageDiscount,
  PACKAGE_TYPES,
  PACKAGE_NAMES,
  handleCTAClick,
  PRICE_TYPE
} from '../common';
import PromoSaveStamp from '../PromoSaveStamp/PromoSaveStamp';
import styles from './PackageCard.module.scss';

export interface IPackageCardProps {
  CTA: (props: any) => void;
  type: PACKAGE_NAMES;
  useCase: string;
  title: string;
  showLocations?: boolean;
  locations?: string[];
  holidaySale?: HolidaySale;
  priceLabel?: ReactElement;
  ctaText?: string;
}

const PackageCard = (props: IPackageCardProps) => {
  return (
    <div>
      <div>
        <Card {...props} />
      </div>
    </div>
  );
};

function Card({
  CTA,
  type,
  useCase,
  title,
  showLocations = false,
  locations,
  holidaySale,
  priceLabel,
  ctaText
}: IPackageCardProps) {
  const theme = useTheme();
  const saleDiscount = () => salePackageDiscount(type, holidaySale);
  const handleClick = () => {
    handleCTAClick(CTA, type);
  };

  const [expanded, setExpanded] = useToggle(false);

  const handleToggle = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setExpanded(!expanded);
  };

  const startingPriceText = type === PACKAGE_NAMES.FULL
    ? 'Starting at'
    : 'First room at';

  return (
    <div className={classNames(
      styles.Card,
    )}
    >
      <PromoSaveStamp className={styles.Stamp} packageDiscount={saleDiscount()} />

      <div className={styles.CardContainer}>
        <div
          className={classNames(
            styles.Content
          )}
        >
          <div>
            <h3 className={styles.Title}>
              {title}
            </h3>

            <div className={styles.UseCaseGrouping}>
              {useCase}
            </div>
          </div>

          <div className={classNames(
            styles.Pricing
          )}
          >
            {priceLabel ? (
              <div className={styles.PriceLabel}>
                {priceLabel}
              </div>
            ) : (
              <>
                <div className={styles.PriceLabel}>
                  {startingPriceText}
                </div>
                <span
                  className={classNames(styles.FullPrice)}
                >
                  {getPrice(saleDiscount()?.msrp, 'msrp', type)}
                </span>
                &nbsp;
                <span className={classNames(styles.SalePrice, {
                  [styles.sale]: saleDiscount(),
                })}
                >
                  {getPrice(saleDiscount()?.salePrice, 'sale', type)}
                </span>

                {saleDiscount()?.addOnPrice ? (
                  <div className={styles.PriceBottom}>
                    plus {getPrice(saleDiscount()?.addOnPrice, 'addon', type)} for each additional room
                  </div>
                ) : (
                  <div className={styles.PriceBottom}>
                    {type !== PACKAGE_NAMES.AT_HOME ? 'per room' : '' }
                  </div>
                )}
              </>
            )}
          </div>

          <GetStarted
            pack={type}
            onClick={handleClick}
            ctaText={ctaText}
          />

          <If condition={showLocations}>
            <div className={styles.ToggleWrapper}>
              <AvailableLocationsToggle
                toggle={(ev) => handleToggle(ev)}
                expanded={expanded}
              />
            </div>
            <div
              className={classNames(
                styles.AvailableLocationsContent,
                theme.styles.Body,
                {
                  [styles.open]: expanded,
                }
              )}
            >
              {locations ? locations.join(', ') : ''}
            </div>
          </If>
        </div>
      </div>
    </div>
  );
}

interface IAvailableLocationsToggle {
  toggle: (ev: SyntheticEvent) => void;
  expanded: boolean;
  disabled?: boolean;
}

function AvailableLocationsToggle({
  toggle,
  expanded,
  disabled = false,
}: IAvailableLocationsToggle) {
  const theme = useTheme();
  const style = expanded ? styles.Expanded : styles.Retracted;

  return (
    <button
      className={classNames(
        styles.AvailableLocations,
        theme.styles.Body
      )}
      onClick={toggle}
      type="button"
      disabled={disabled}
    >
      {'Available locations'}
      <Caret className={classNames(styles.Caret, style)} />
    </button>
  );
}

interface IGetStartedProps {
  onClick: () => void;
  pack: PACKAGE_TYPES;
  merch?: boolean;
  ctaText?: string;
}

function GetStarted({
  onClick, pack, ctaText
}: IGetStartedProps) {
  const theme = useTheme();
  const getCtaText = () => {
    if (ctaText) return ctaText;

    if (pack === 'at home') {
      return 'Get started in-person';
    } else if (pack === 'merch') {
      return 'Buy merch credit';
    } else {
      return `Get started online`;
    }
  };

  const getPackageType = () => {
    if (pack === 'at home') {
      return PACKAGES.AT_HOME;
    }
    if (pack === 'full') {
      return PACKAGES.FULL;
    }
    if (pack === 'mini') {
      return PACKAGES.MINI;
    }
    if (pack === 'merch') {
      return PACKAGES.MERCH;
    }

    return PACKAGES.FULL;
  };

  return (
    <div>
      <button
        type="button"
        className={classNames(
          theme.styles.Button,
          theme.styles.Medium,
          styles.CtaWidth,
          theme.styles.Primary
        )}
        data-test={`select-package-${getPackageType()}`}
        onClick={onClick}
      >
        <span>
          {getCtaText()}
        </span>
      </button>
    </div>
  );
}

function getPrice(
  discount: number | undefined,
  priceType: PRICE_TYPE,
  packageType: PACKAGE_TYPES,
) {
  return formatCost(discount || PRICES[packageType][priceType]);
}

export default PackageCard;
